import { Link, useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { tokenState } from "state";
import { BASE_URL, cls, api, marketUrl } from "../../utils/util";
import AlarmHeader from "./AlarmHeader";
import { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { useQuery } from "react-query";
import { headerColorState } from "state";
import { EDU_ROUTE, CLASS_ROUTE } from "App";
import HeaderFlag from "./HeaderFlag";

import WingBanner from "component/advertisment/banner";
import { isMarketOnlyUser, myPageUrl } from "utils/marketUtil";
import Logo, { ChicruitingLogo } from "../Logo";

const Header = ({ current }) => {
  const token = useRecoilValue(tokenState);
  const location = useLocation();
  const navigate = useNavigate();
  const headerColor = useRecoilValue(headerColorState);
  const user = token && token.user_type;
  const [scrolled, setScrolled] = useState(window.scrollY > 60);
  const { data: userCount, isLoading: userCountLoading } = useQuery(
    "utilUserCount",
    () => axios.get(`${BASE_URL}/util/user_count`).then((res) => res.data),
    { cacheTime: Infinity, staleTime: 15 * 60 * 1000 },
  );
  const { data: boardList } = useQuery(
    "boardCateCd",
    () => api.get("/board/cate_cd").then((res) => res.data.cate_list),
    {
      cacheTime: 15 * 60 * 1000,
      staleTime: 15 * 60 * 1000,
      enabled: !!(user === "student" || user === "doctor"),
    },
  );
  const [searchKeyword, setSearchKeyword] = useState("");

  const handleSearchKeyword = (e) => {
    setSearchKeyword(e.target.value);
  };

  const tabs = [
    {
      label: "클래스",
      value: "class",
      url: "/class",
      subTabs: [
        { label: "클래스", value: "class", url: `/${CLASS_ROUTE}` },
        { label: "보수교육", value: `edu`, url: `/${EDU_ROUTE}` },
        { label: "통치", value: "tongtest", url: "/tongtest" },
      ],
    },
    {
      label: "마켓",
      value: "market",
      url: marketUrl(),
      subTabs: [],
    },
    { label: "콘텐츠", value: "contents", url: "/contents", subTabs: [] },
    { label: "게시판", value: "board", url: "/post", subTabs: [] },
    {
      label: "Q&A",
      value: "qna",
      url: "/qna",
      subTabs: [
        { label: "치과의사", value: "doctor", url: "/qna/doctor" },
        { label: "치대생", value: "student", url: "/qna/student" },
        { label: "AI 임상검색", value: "ai", url: "/ai" },
      ],
    },
    {
      label: "모어덴 AI",
      value: "ai",
      url: "/ai",
      subTabs: [],
    },
    { label: "임상포럼", value: "forum", url: "/forum", subTabs: [] },
    {
      label: "개원/경영",
      value: "management",
      url: "/management",
      subTabs: [
        { label: "컨설팅", value: "consulting", url: "/management/consulting" },
        { label: "금융/대출", value: "loan", url: "/management/loan" },
        { label: "기공소", value: "lab", url: "/management/lab" },
        { label: "병원 양도/임대", value: "estate", url: "/management/estate" },
        { label: "중고장터", value: "market", url: "/management/market" },
      ],
    },
    { label: "치크루팅", value: "chicruiting", url: "/chicruiting/company/people", subTabs: [] },
    {
      label: "구인구직",
      value: "recruit",
      url: "/recruit/doctor",
      subTabs: [],
    },

    {
      label: "세미나",
      value: "seminar",
      url: "/management/seminar",
      subTabs: [
        { label: "세미나", value: "seminar", url: "/management/seminar" },
        { label: "주간세미나", value: "seminar_weekly", url: "/management/seminar_weekly" },
      ],
    },
    { label: "상영관", value: "expo", url: "/expo", subTabs: [] },
    { label: "전치제", value: "festival", url: "/festival", subTabs: [] },
  ];

  const filteredTabs = tabs.filter(
    ({ value }) => !isMarketOnlyUser(token?.user_type) || value === "market",
  );

  const currentHasSubTab = tabs.find(
    (tab) => current === tab.value && (tab.subTabs.length > 0 || tab.value === "board"),
  );

  const onPageScroll = () => {
    setScrolled(window.scrollY > 60);
  };

  useEffect(() => {
    window.addEventListener("scroll", onPageScroll, false);
    return () => {
      window.removeEventListener("scroll", onPageScroll, false);
    };
  }, []);

  const isShowHeaderWingBanner = useMemo(() => {
    const locationPath = location.pathname.split("/")[1];
    return !["market"].includes(locationPath);
  }, [location.pathname]);

  return (
    <section className={cls(scrolled ? (currentHasSubTab ? "pb-[155px]" : "pb-[120px]") : "")}>
      <header
        className={cls(
          scrolled ? "fixed" : "relative",
          "top-0 left-0 right-0 flex flex-col z-[100] w-[1200px] mx-auto",
        )}
      >
        {isShowHeaderWingBanner && (
          <>
            <div
              className={cls(
                "2xl:flex hidden",
                "absolute flex flex-col gap-[12px] w-[120px]",
                scrolled ? "top-[56px] left-[-140px]" : "top-[114px] left-[-140px]",
              )}
            >
              <WingBanner name="pc_all_wing_left_top" page="all" />
              <WingBanner name="pc_all_wing_left_middle" page="all" />
              <WingBanner name="pc_all_wing_left_bottom" page="all" />
            </div>
            <div
              className={cls(
                "2xl:flex hidden",
                "absolute flex flex-col gap-[12px] w-[120px]",
                scrolled ? "top-[56px] right-[-140px]" : "top-[114px] right-[-140px]",
              )}
            >
              <WingBanner name="pc_all_wing_right_top" page="all" />
              <WingBanner name="pc_all_wing_right_middle" page="all" />
              <WingBanner name="pc_all_wing_right_bottom" page="all" />
            </div>
          </>
        )}{" "}
        <div
          className={cls(
            scrolled ? "hidden" : "w-[1200px] mx-auto flex justify-between items-center h-[60px]",
          )}
        >
          <Link to="/">
            <Logo color={headerColor} />
          </Link>
          <div className="flex items-center mt-3 mr-3 gap-4">
            <form
              className="text-[14px] relative"
              onSubmit={(e) => {
                e.preventDefault();
                const q = searchKeyword.trim();
                navigate(`/search?indexUid=all&q=${q}`);
                setSearchKeyword("");
              }}
            >
              <i className="ii ii-search absolute left-[1px] px-2.5 h-full flex items-center leading-[24px] text-[24px] text-gray-800"></i>
              <input
                type="search"
                className="w-[320px] h-[40px] pl-[44px] py-2 rounded-md bg-gray-50 border border-solid border-secondary-200 placeholder-primary-800 focus:ring-1 focus:ring-primary-800 search-cancel:appearance-none focus:placeholder-opacity-0"
                value={searchKeyword}
                onChange={handleSearchKeyword}
                placeholder={'"공동구매"를 검색해 보세요'}
              />
              {searchKeyword && (
                <i
                  className="ii ii-remove-circle h-full leading-[20px] text-[20px] text-gray-800 absolute top-0 right-[1px] cursor-pointer px-2.5 flex items-center"
                  onClick={() => setSearchKeyword("")}
                ></i>
              )}
            </form>
            <div className="flex items-center gap-2">
              {user && !isMarketOnlyUser(user) && (
                <AlarmHeader>
                  <i
                    className={cls(
                      headerColor
                        ? `text-${headerColor} hover:bg-white/10`
                        : "text-gray-600 hover:bg-gray-100",
                      "ii ii-bell-outline text-[28px] w-[40px] h-[40px] flex justify-center items-center rounded-full",
                    )}
                  />
                </AlarmHeader>
              )}
              <Link to={myPageUrl(token?.user_type)}>
                <i
                  className={cls(
                    headerColor
                      ? `text-${headerColor} hover:bg-white/10`
                      : "text-gray-600 hover:bg-gray-100",
                    "ii ii-user-outline text-[28px] w-[40px] h-[40px] flex justify-center items-center rounded-full",
                  )}
                />
              </Link>
            </div>
          </div>
        </div>
        <div
          className={cls(
            !scrolled && currentHasSubTab ? "border-b-[1px] h-[95px]" : "",
            "border-solid border-gray-200 border-t-[1px] border-opacity-70",
          )}
        >
          <nav
            className={cls(
              scrolled ? "bg-white border-b-[1px]" : "",
              currentHasSubTab ? "border-b-[1px]" : "",
              "border-solid border-gray-300 = border-opacity-70",
            )}
          >
            <div className="w-[1200px] mx-auto flex">
              {filteredTabs.map((tab) => {
                return (
                  <ul
                    className="relative px-4 first:pl-2 whitespace-nowrap flex-shrink-0"
                    key={tab.label}
                  >
                    {!scrolled && <HeaderFlag value={tab.value} />}
                    <TabItem current={current} tab={tab} color={scrolled ? null : headerColor}>
                      {!scrolled && tab.value === "board" && current === tab.value && (
                        <nav className="mt-2 w-[1050px] scroll_none overflow-scroll absolute flex text-gray-600">
                          {boardList &&
                            boardList.map((subTab) => {
                              return (
                                <ul
                                  className="relative px-4 first:pl-0 whitespace-nowrap"
                                  key={subTab.cate_name}
                                >
                                  <BoardSubTabItem tab={subTab} />
                                </ul>
                              );
                            })}
                        </nav>
                      )}
                      {!scrolled && tab.subTabs.length > 0 && current === tab.value && (
                        <nav className="absolute w-0 flex text-gray-600">
                          {tab.subTabs.map((subTab) => {
                            return (
                              <ul
                                className="relative px-4 first:pl-0 whitespace-nowrap"
                                key={subTab.label}
                              >
                                <SubTabItem
                                  pathname={location.pathname}
                                  tab={subTab}
                                  color={scrolled ? null : headerColor}
                                />
                              </ul>
                            );
                          })}
                        </nav>
                      )}
                    </TabItem>
                  </ul>
                );
              })}
            </div>
          </nav>
        </div>
      </header>
    </section>
  );
};

export default Header;

const TabItem = ({ current, tab, children, color }) => {
  return (
    <>
      <Link
        className={cls(
          color ? `text-${color}` : "",
          current === tab.value
            ? color
              ? "text-opacity-100 font-bold"
              : "text-primary-800 font-medium"
            : color
            ? "text-opacity-50 font-medium hover:text-opacity-100 hover:font-bold"
            : "font-medium hover:text-primary-800",
          tab.label === "모어덴 AI" && "tab-gradient-txt",
          "text-[14px] py-4 flex items-center",
        )}
        to={tab.url}
      >
        {tab.label === "모어덴 AI" && (
          <div className="h-[15px] mr-1 bg-gradient-to-r from-[#6533E8] to-[#607EE1] px-[3px] text-white rounded-sm flex items-center">
            <span className="w-[10px] h-[10px] bg-[url('/img/ai/icon-badge.svg')] bg-no-repeat bg-center bg-cover"></span>
            <span className="!text-[#fff] text-[11px] font-bold !bg-transparent">AI</span>
          </div>
        )}
        {tab.value === "chicruiting" ? <ChicruitingLogo width={74} color={color} /> : tab.label}
      </Link>
      {children}
    </>
  );
};

const SubTabItem = ({ pathname, tab, color }) => {
  // url이 /management/seminar_weekly일 때 /management/seminar도 하이라이트 되는 문제 해결
  const seminarException =
    pathname.includes("/management/seminar_weekly") && tab.url === "/management/seminar";

  // url이 /recruit이거나 /recruit/chicruiting일 때 치과위생사가 하이라이트 되도록 함
  const recruitException =
    (pathname === "/recruit" && tab.url === "/recruit/hygienist") ||
    (pathname.includes("/recruit/chicruiting") && tab.url === "/recruit/hygienist");

  return (
    <>
      <Link
        className={cls(
          color ? `text-${color}` : "",
          (pathname.includes(tab.url) && !seminarException) || recruitException
            ? color
              ? `text-opacity-100 font-bold border-b-[3px] border-solid border-${color}`
              : `text-primary-800 font-medium border-b-[3px] border-solid border-primary-800`
            : color
            ? "text-opacity-50 hover:text-opacity-100"
            : "text-gray-600 hover:text-primary-800",
          "text-[14px] py-[9px] flex items-center",
        )}
        to={tab.url}
      >
        {tab.label === "AI 임상검색" && (
          <span className="w-4 h-4 mr-1 bg-[url('/img/ai/icon-badge-gradient.svg')] bg-no-repeat bg-center bg-cover"></span>
        )}
        {tab.label}
      </Link>
    </>
  );
};

const BoardSubTabItem = ({ tab }) => {
  const location = useLocation();
  const urlParam = new URLSearchParams(location.search);
  const board = parseInt(urlParam.get("cate")) || 0;

  return (
    <Link
      className={cls(
        board === tab.cate_cd
          ? "text-primary-800 border-b-[3px] border-solid border-primary-800"
          : "text-gray-600",
        "text-[14px] py-[9px] hover:text-primary-800",
      )}
      to={`/post?cate=${tab.cate_cd}`}
    >
      {tab.cate_name}
    </Link>
  );
};
